
const enable = true;

export let required = (v) => {
   if(v == null){return true}
   if(!enable){return true}

   return !!v || 'Campo Obrigatório'

}

export let max10 = (v) => {
   if(v == null){return true}
   if(!enable){return true}

   return v.length <= 10 || 'Campo deve ter menos de 10 caracters'

}

export let min3 = (v) => {
   if(v == null){return true}
   if(!enable){return true}

   return v.length >= 3 || 'Campo deve ter pelo menos 3 caracters'

}

export let max200 = (v) => {
   if(v == null){return true}
   if(!enable){return true}

   return v.length <= 200 || 'Campo deve ter menos de 200 caracters'



}

export let email = (v) => {
   if(v == null){return true}
   if(!enable){return true}

   if(v.length > 0){
      return /.+@.+/.test(v) || 'Email inválido'
   }
}
