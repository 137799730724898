<template>
  <v-container>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      sort-by="corporate_name"
      :page-count="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }"
    >
      <!-- taxa -->
      <template v-slot:item.tax="{ item }">
      {{ taxCreditOrDebito(item) }}
      </template>

      <!-- taxa -->
      <template v-slot:item.operation_type="{ item }">
      {{ opertionType(item) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Cartão</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify "
            single-line
            label="Localizar"
            class=""
            outlined
            filled
            rounded
            dense
            hide-details
            @click:append="searchItems()"
            @change="searchItems()"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>{{ icons.mdiPlus }} </v-icon>Novo Cartão
              </v-btn>
            </template>

            <v-card>
              <!-- <div class="title_body" >
                <div class="bar_top" ></div>
                <div class="text_title text-h6">{{ formTitle }}</div>

                <span
                  class="btn_close"
                ><v-btn
                  icon
                  @click="dialog = false"
                ><v-icon > {{ icons.mdiWindowClose }}  </v-icon></v-btn>
                </span>
              </div> -->
              <BarTitleDialog :title="formTitle" @close="dialog = false"></BarTitleDialog>
              <v-card-text>
                <v-form
                  ref="form"
                  lazy-validation
                >

                    <v-row no-gutters>
                      <v-col v-if="editedIndex != -1" cols="2">
                        <v-text-field
                          class="mr-2"
                          v-model="editedItem.id"
                          disabled
                          dense
                          outlined
                          label="Codigo"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.name"
                          outlined
                          label="Nome"
                          :rules="[v.required, v.max200]"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col>
                        <v-select
                          v-model="editedItem.operation_type"
                          outlined

                          :items="type_card"
                          item-text="name"
                          item-value="value"
                          label="Tipos de Operações"
                          ></v-select>
                      </v-col>
                   </v-row>

                    <v-row no-gutters v-if="editedItem.operation_type != 'credito'" >
                      <v-col >
                        <vuetify-money
                          v-model="editedItem.debit_rate"
                          outlined
                          label="Taxa de Cartão de Débito"
                          append-icon="icons.mdiPlus"
                          :options="{locale: 'pt-BR',length: 11, precision: 2, suffix:'%' }"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editedItem.operation_type != 'credito'" >
                      <v-col >
                      <vuetify-money
                        v-model="editedItem.debit_busy_days"
                        outlined

                        label="Repasse Operadora Cartão de Débito"
                        :options="{locale: 'pt-BR',length: 2, precision: 0, suffix:'Dias' }"

                      />

                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editedItem.operation_type != 'debito' ">
                      <v-col >
                      <vuetify-money
                        v-model="editedItem.credit_rate"
                        outlined
                        label="Taxa Cartão de Crédito Avista"
                        :options="{locale: 'pt-BR',length: 11, precision: 2, suffix:'%' }"
                      />
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editedItem.operation_type != 'debito' ">
                      <v-col >
                      <vuetify-money
                        v-model="editedItem.number_installment"
                        outlined

                        type="number"
                        label="Numero de Parcelas no Cartão de Crédito"
                        :options="{locale: 'pt-BR',length: 2, precision: 0}"
                      />

                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editedItem.operation_type != 'debito' ">
                      <v-col >
                       <v-select
                          v-model="editedItem.term_type"
                          outlined
                          :items="items_term_type"
                          item-text="name"
                          item-value="value"
                          label="Tipo de Prazo de Repasse do Cartão de Crédito"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-if="editedItem.operation_type != 'debito' && editedItem.term_type == 'dias_corridos'">
                      <v-col >
                      <vuetify-money
                        v-model="editedItem.credit_busy_days"
                        outlined

                        label="Repasse Operadora Cartão de Crédito"
                          :options="{locale: 'pt-BR',length: 2, precision: 0,suffix:'Dias'}"
                      />

                      </v-col>
                    </v-row>

                  </v-form>

                  <div class="card-actions mt-0">

                  </div>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-row>
              <v-col class="text-right">
                    <v-btn
                      class="primary btn-actions"
                      :loading="btnLoadingSave"
                      :disabled="btnLoadingSave"
                      @click="save"
                      x-large
                    >
                      Salvar
                    </v-btn>
                    <v-btn
                      x-large
                      text
                      class="btn-actions"
                      @click="dialog = false"
                    >
                      Fechar
                    </v-btn>

              </v-col>
            </v-row>
                </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog
            v-model="dialogDelete"
            max-width="550px"
          >
            <v-card>
              <v-card-title class="text-h6 text-center">
                Tem certeza de que deseja excluir este item ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="closeDelete"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  :loading="loadingDelete"
                  class="error"
                  @click="deleteItemConfirm"
                >
                  Sim
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="me-2"
          @click="editItem(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
        <v-icon @click="deleteItem(item)">
          {{ icons.mdiDeleteOutline }}
        </v-icon>
      </template>

      <template v-slot:no-data>
        Nenhum registro encontrado
      </template>
    </v-data-table>
   </v-container>
</template>

<script>

import { mapActions } from 'vuex'
import { mdiPencilOutline, mdiDeleteOutline,mdiWindowClose, mdiMagnify,mdiPlus } from '@mdi/js'
import * as validations from '@/plugins/rules-validations'

import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {

  components:{
    BarTitleDialog
  },
  data: () => ({
    v: validations,
    type_card:[{name:'Débito',value:'debito'},{name:'Crédito',value:'credito'},{name:'Debito/Crédito',value:'debito_credito'}],
    items_term_type:[{name:'Dias Corridos',value:'dias_corridos'},{name:'No Mesmo Dia do Proximo Mês ',value:'mesmo_dia_do_proximo_mes'}],
    categoryProduct:[],
    icons: {
      mdiDeleteOutline,
      mdiPencilOutline,
      mdiWindowClose,
      mdiMagnify ,
      mdiPlus
    },
    loadingDelete:false,
    menu_date2: false,
    loadingCities: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    search: '',

    headers: [
      {
        text: 'Codigo', width: 0, align: 'start', sortable: false, value: 'id',
      },
      { text: 'Nome', value: 'name' },
      { text: 'Tipo de Operação', value: 'operation_type' },
      { text: 'Taxa ( % )', value: 'tax' },
      {
        text: '', value: 'actions', sortable: false, align: 'end',
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name:"",
      operation_type:"debito_credito",
      debit_rate:"",
      debit_busy_days:"",
      credit_rate:"",
      number_installment:"",
      term_type:"mesmo_dia_do_proximo_mes",
      credit_busy_days:"",
    },
    defaultItem: {
      name:"",
      operation_type:"debito_credito",
      debit_rate:"",
      debit_busy_days:"",
      credit_rate:"",
      number_installment:"",
      term_type:"mesmo_dia_do_proximo_mes",
      credit_busy_days:"",
    },

  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Cadastrar Cartão' : 'Editar Cartão'
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
    deep: true,
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {


    this.initialize()
  },

  methods: {
    ...mapActions('card', [
      'getAll',
      'destroy',
      'update',
      'store',
      ]
    ),
    initialize() {
      this.loading_data_table = true

      const { page, itemsPerPage } = this.options

      let payload = {
          itemsPerPage: itemsPerPage||10,
          page,
          search:this.search,
        }

      this.getAll(payload).then(response => {
        this.desserts = response.data.data
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false
      })
    },
    searchItems() {
      this.options = {}
      this.initialize()
    },
    taxCreditOrDebito(item){
      switch (item.operation_type) {
        case 'debito': return this.formatMoney(item.debit_rate)
           break;
        case 'credito': return this.formatMoney(item.credit_rate)
           break;
        case 'debito_credito': return this.formatMoney(item.debit_rate)+' / '+this.formatMoney(item.credit_rate)
           break;


      }
      if (item.operation_type == 'debito'){
          return this.formatMoney(item.debit_rate)
      }
    },
    opertionType(item){
      switch (item.operation_type) {
        case 'debito': return 'Débito'
           break;
        case 'credito': return 'Crédito'
           break;
        case 'debito_credito': return 'Débito/Crédito'
           break;


      }

      return item
    },

    veriOperationType(){

        switch (this.editedItem.operation_type) {
        case 'debito':
            this.editedItem.credit_rate = "",
            this.editedItem.number_installment = "",
            this.editedItem.term_type = "mesmo_dia_do_proximo_mes",
            this.editedItem.credit_busy_days=""
        break;
        case 'credito':
          this.editedItem.debit_rate=""
          this.editedItem.debit_busy_days=""
          if( this.editedItem.term_type == "mesmo_dia_do_proximo_mes" ){
            this.editedItem.credit_busy_days=""
          }

         break;
         case 'debito_credito': return 'Débito/Crédito'
          if( this.editedItem.term_type == "mesmo_dia_do_proximo_mes" ){
              this.editedItem.credit_busy_days=""
          }
           break;
      }
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
        this.loadingDelete=true
      const { editedIndex } = this
      this.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1)
        this.$toast.success('Deletado com sucesso.')
      }).finally(()=>{
        this.loadingDelete=false
      })

      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    save() {

      if (!this.validate()) { return }
      this.btnLoadingSave = true
      this.veriOperationType()
      if (this.editedIndex > -1) {
        // update
        const indice = this.editedIndex

        this.update(this.editedItem)
          .then(response => {

            Object.assign(this.desserts[indice], response.data)
            this.close()
            this.$toast.success('Salvo com sucesso.')
          })
          .catch(() => {

          }).finally(()=>{
            this.btnLoadingSave = false
          })
      } else {
        // store
        this.store(this.editedItem)
          .then(response => {

            this.desserts.push(response.data)
            this.close()
            this.$toast.success('Salvo com sucesso.')

            if (this.desserts.length === 1) {
              this.initialize()
            }
          })
          .catch(() => {

          }).finally(()=>{
            this.btnLoadingSave = false
          })
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    formatMoney(value){
      return value.toLocaleString('pt-br', {minimumFractionDigits: 2})
    }

  },
}
</script>
